import {
  Box,
  Button,
  FormControl,
  IconProps,
  Image,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { getTitleCase } from '../../../helpers/utilityFunctions';
import { ChevronDownIcon, InputCheckIcon } from '../../icons';

export interface CustomDropdownMenuProps {
  options: { value: string; label: string; img?: string; searchBy?: string }[];
  placeholder: string;
  setFieldValue: (field: string, value: string) => void;
  fieldValue: string;
  value: string;
  useIndexAsKey?: boolean;
  dropdownHeader?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  menuWidth?: string;
  searchable?: boolean;
  searchFieldPlaceholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: any) => void;
  OptionIcon?: (props: IconProps) => JSX.Element;
  InputFieldIcon?: (props: IconProps) => JSX.Element;
  retainTextTransformation?: boolean;
  fontWeight?: string | number;
  variant?: 'standard' | 'filled';
  renderLimit?: number;
  border?: string;
  noDropdownIcon?: boolean;
  padding?: string;
  height?: string | number;
  maxWidth?: string | number;
  showSelectedOptionImage?: boolean;
}

export default function CustomDropdownMenu({
  options,
  placeholder,
  dropdownHeader,
  setFieldValue,
  fieldValue,
  value,
  isLoading,
  isDisabled,
  searchable,
  searchFieldPlaceholder,
  onSelect,
  OptionIcon,
  InputFieldIcon,
  retainTextTransformation,
  border,
  useIndexAsKey,
  renderLimit,
  noDropdownIcon,
  padding,
  height,
  maxWidth,
  fontWeight = 400,
  menuWidth = '100%',
  variant = 'standard',
  showSelectedOptionImage = false,
}: CustomDropdownMenuProps) {
  const [selectedOption, setSelectedOption] = useState(value ?? '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (selectedOption !== value) {
      setSelectedOption(value);
    }
  }, [selectedOption, value]);

  const handleSelectOption = (value: string) => {
    setSelectedOption(value);
    setFieldValue(fieldValue, value);
    onSelect?.(value);
  };

  const selectedOptionLabel = options.find(
    (option) => option.value === selectedOption
  )?.label;

  const selectedOptionImage = options.find(
    (option) => option.value === selectedOption
  )?.img;

  const handleSearchOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchKey(value);

    const valueToSearchBy = value.toLowerCase().trim();

    if (valueToSearchBy === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter(
          (option) =>
            option.label.toLowerCase().includes(valueToSearchBy) ||
            (option.searchBy &&
              option.searchBy.toLowerCase().includes(valueToSearchBy))
        )
      );
    }
  };

  let menuStyle: MenuButtonProps['sx'];

  if (variant === 'standard') {
    menuStyle = {
      height: height ?? '56px',
      padding: padding ?? '8px 24px',
      borderRadius: '14px',
    };
  } else {
    menuStyle = {
      height: height ?? '80px',
      padding: padding ?? '16px 24px',
      borderRadius: '12px',
    };
  }

  const amountOfItemsToRender = renderLimit
    ? searchKey.length > 2
      ? 100
      : renderLimit
    : filteredOptions.length;

  return (
    <Menu>
      <MenuButton
        isDisabled={isDisabled}
        as={Button}
        rightIcon={noDropdownIcon ? undefined : <ChevronDownIcon />}
        sx={{
          fontWeight,
          border: border ?? '1px solid #EBEDEF',
          background: '#fff',
          width: '100%',
          maxWidth: maxWidth ?? '100%',
          color: '#1A1C1F',
          fontSize: '.875rem',
          _hover: { bg: 'transparent' },
          _expanded: { bg: 'transparent' },
          textAlign: 'left',
          ...menuStyle,
        }}
      >
        <Box display="flex" gap="2" alignItems="center" overflow="auto">
          {InputFieldIcon && <InputFieldIcon />}
          {variant === 'standard' ? (
            <>
              {showSelectedOptionImage && selectedOptionImage && (
                <Image
                  src={selectedOptionImage}
                  alt=""
                  width="20px"
                  loading="lazy"
                />
              )}
              <Text maxWidth={maxWidth} noOfLines={1}>
                {selectedOptionLabel ?? placeholder}
              </Text>
            </>
          ) : (
            <Box>
              <Text
                mb="2"
                color="gray.300"
                fontSize=".75rem"
                lineHeight="133.33%"
              >
                {placeholder}
              </Text>
              <Box display="flex" gap="5px ">
                {showSelectedOptionImage && selectedOptionImage && (
                  <Image
                    src={selectedOptionImage}
                    alt=""
                    width="20px"
                    loading="lazy"
                  />
                )}
                <Text
                  fontSize="1rem"
                  fontWeight={500}
                  maxWidth={maxWidth}
                  noOfLines={1}
                >
                  {selectedOptionLabel}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </MenuButton>

      <MenuList
        width={{ base: '100%', sm: menuWidth }}
        maxHeight="500px"
        overflow="scroll"
        zIndex={100}
      >
        {searchable && (
          <MenuItem
            isDisabled
            onClick={(e) => {
              e.preventDefault();
            }}
            _disabled={{
              opacity: 1,
            }}
            sx={{
              padding: 0,
            }}
          >
            <FormControl pos="sticky" top="0" background="#fff">
              <InputGroup>
                {/* <InputLeftElement pointerEvents="none" pt="4" pb="2.5">
                <SearchIcon />
              </InputLeftElement> */}
                <Input
                  variant="rounded"
                  placeholder={searchFieldPlaceholder ?? 'Search'}
                  value={searchKey}
                  type="search"
                  onChange={handleSearchOptions}
                  sx={{
                    width: '100%',
                    backgroundColor: 'ash.100',
                    height: '44px',
                    borderRadius: '14px',
                    ':focus': {
                      borderRadius: '14px',
                      boxShadow: '0',
                    },
                    ':not(:placeholder-shown), :not(:focus)': {
                      borderRadius: '14px',
                    },
                  }}
                />
              </InputGroup>
            </FormControl>
          </MenuItem>
        )}

        {dropdownHeader && (
          <Text
            color="gray.300"
            fontSize=".6875rem"
            lineHeight="136.364%"
            padding="8px"
            textTransform="uppercase"
          >
            {dropdownHeader}
          </Text>
        )}
        {isLoading ? (
          <MenuItem
            onClick={() => {
              // Do nothing
            }}
            padding="8px"
            borderRadius="4px"
            sx={{
              _hover: {
                background: 'transparent',
              },
              _focus: { background: 'transparent' },
              width: '100%',
            }}
          >
            <SkeletonText width="100%" skeletonHeight={4} endColor="#f9fafa" />
          </MenuItem>
        ) : (
          filteredOptions
            .slice(0, amountOfItemsToRender)
            .map((option, index) => (
              <MenuItem
                id={index.toString()}
                key={useIndexAsKey ? index : option.value + option.label}
                onClick={() => handleSelectOption(option.value)}
                fontSize=".875rem"
                padding="8px"
                borderRadius="8px"
                sx={{
                  _hover: {
                    background: '#EBEDEF',
                  },
                  width: '100%',
                }}
                background={value === option.value ? 'ash.200' : 'initial'}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={3}
                >
                  <Box display="flex" gap={3} alignItems="center">
                    {OptionIcon && <OptionIcon />}
                    {option?.img && (
                      <Image
                        src={option.img}
                        alt=""
                        width="20px"
                        loading="lazy"
                      />
                    )}
                    <Text
                      fontSize=".875rem"
                      lineHeight="142.857%"
                      noOfLines={1}
                    >
                      {retainTextTransformation
                        ? option.label
                        : getTitleCase(option.label)}
                    </Text>
                  </Box>

                  {value === option.value && <InputCheckIcon />}
                </Box>
              </MenuItem>
            ))
        )}
        {renderLimit && searchKey.length < 2 ? (
          <Text
            color="gray.300"
            fontSize=".6875rem"
            lineHeight="136.364%"
            padding="8px"
          >
            {searchFieldPlaceholder ?? 'Search'} to show other options
          </Text>
        ) : (
          ''
        )}
      </MenuList>
    </Menu>
  );
}
