import { useEffect, useState } from 'react';
import React from 'react';

import {
  CustomDrawerBackButton,
  CustomDrawerHeader,
} from '../../../../components/custom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getDirectorAdditionalInfoAsync } from '../../../../store/slices/addDirector/getDirectorAdditionalInfoSlice';
import AdditionalDocumentsForm from './DirectorAdditionalDocumentForm';
import AdditionalInformationType from './DirectorsAdditionalInfoType';

export type TStep = 'informationType' | 'documents';

const AdditionalInformationSteps = ({ close }: { close: () => void }) => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState<TStep>('informationType');
  const [directorId, setDirectorId] = useState<string>('');

  const { value } = useAppSelector((state) => state.getDirectorAdditionalInfo);

  const directorDocument = value?.data?.filter((x) => x.id === directorId);

  const directorArray = Object.values(
    directorDocument?.[0]?.infoRequestedChecklist?.[0] ?? {}
  );

  const directorCount = value?.data?.length ?? 0;

  useEffect(() => {
    dispatch(
      getDirectorAdditionalInfoAsync({
        approvalStatus: 'information_requested',
      })
    );
  }, [dispatch]);

  if (currentStep === 'documents') {
    return (
      <>
        <CustomDrawerBackButton
          onClick={() => setCurrentStep('informationType')}
        />
        <AdditionalDocumentsForm
          directorId={directorId}
          documentNames={directorArray ?? []}
          setCurrentStep={setCurrentStep}
          directorCount={directorCount}
          close={close}
        />
      </>
    );
  }

  return (
    <>
      <CustomDrawerHeader startsFromBeginning>
        Director Additional Information
      </CustomDrawerHeader>
      <AdditionalInformationType
        setCurrentStep={setCurrentStep}
        setDirectorId={setDirectorId}
      />
    </>
  );
};

export default AdditionalInformationSteps;
