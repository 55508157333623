import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import TeamImage from '../../../../assets/images/team-image-rep.svg';
import { CustomDrawer } from '../../../../components/custom';
import { NotificationDotIcon } from '../../../../components/icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getDirectorAdditionalInfoAsync } from '../../../../store/slices/addDirector/getDirectorAdditionalInfoSlice';
import AdditionalInformationSteps from './DirectorsAdditionalInfoStep';

export default function DirectorsAdditionalInfoCard() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { status: directorsStatus, value: directorsValue } = useAppSelector(
    (state) => state.getDirectorAdditionalInfo
  );

  const showOnRoutes = ['/app/dashboard'];

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(
      getDirectorAdditionalInfoAsync({
        approvalStatus: 'information_requested',
      })
    );
  }, [dispatch]);

  return (
    <>
      {directorsStatus === 'success' && directorsValue?.data?.length ? (
        <>
          <Box
            display={
              showOnRoutes.some((route) => location.pathname.includes(route))
                ? 'inherit'
                : 'none'
            }
            borderRadius="16px"
            border="1px solid #ab94f2"
            background="#CDBEFA"
            minH="104px"
            mb="62px"
            position="relative"
          >
            <NotificationDotIcon
              fill="#E54839"
              position="absolute"
              top="-10px"
              left={{ base: '18px', lg: '24px' }}
            />

            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
              gap={6}
              px={8}
              py={{ base: 4, lg: 'initial' }}
            >
              <GridItem
                ml="12px"
                w="100%"
                position="relative"
                backgroundImage={TeamImage}
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
              />
              <GridItem
                w="100%"
                py={{ base: 0, lg: '6' }}
                mt={{ base: '-5', lg: 'initial' }}
              >
                <Text
                  fontFamily="RecklessNeue"
                  fontSize="1.125rem"
                  fontWeight={700}
                  lineHeight="2rem"
                  mb={{ base: 0, lg: 2 }}
                >
                  Directors Additional Information Required
                </Text>
                <Text fontSize=".875rem" lineHeight="114.286%" noOfLines={2}>
                  You're almost there - just a few more details to enhance your
                  9jaPay experience.
                </Text>
              </GridItem>
              <GridItem
                w="100%"
                py={{ base: 0, lg: '2rem' }}
                textAlign="right"
                alignSelf="center"
              >
                <Button
                  bg="#001419"
                  color="#fff"
                  fontSize=".75rem"
                  fontWeight={400}
                  width={{ base: '100%', lg: 'initial' }}
                  borderRadius={{ base: '8px', lg: '32px' }}
                  p="12px 24px"
                  onClick={onOpen}
                >
                  Continue
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </>
      ) : null}

      <CustomDrawer
        isOpen={isOpen}
        onClose={onClose}
        drawerHeader="Director Additional Information"
      >
        <AdditionalInformationSteps close={onClose} />
      </CustomDrawer>
    </>
  );
}
