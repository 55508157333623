import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from '../../../api/axios';
import { IApiConfig, IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetKybResponse {
  success?: boolean;
  message?: string;
  data?: {
    bvnVerificationStatus?: 'approved' | 'rejected' | 'pending' | 'processing';
    idVerificationStatus?: 'approved' | 'rejected' | 'pending' | 'processing';
    ninVerificationStatus?: 'approved' | 'rejected' | 'pending' | 'processing';
    nationalityVerificationStatus?:
      | 'approved'
      | 'rejected'
      | 'pending'
      | 'processing';
    cacNumber?: string;
    tinNumber?: string;
    businessId?: string;
    businessType?: 'REGISTERED' | 'SOLETRADER';
    documents?: [];
    isCompleted?: boolean;
    isRepresentativeApproved?: boolean;
    hasCdd?: boolean;
    addressVerificationStatus?:
      | 'approved'
      | 'rejected'
      | 'pending'
      | 'processing';
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetKybResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getKybByIdAsync = createAsyncThunk(
  'kyb',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config: IApiConfig = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      if (businessId) {
        config.headers['X-Business-Id'] = businessId;
      }

      const { data } = await api.get(
        'kyb/overview/step-overview',
        config as any
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const kybGetByIdSlice = createSlice({
  name: 'kyb/getById',
  initialState,
  reducers: {
    resetGetKybById: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKybByIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getKybByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetKybResponse;
      })
      .addCase(getKybByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetKybResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetKybById } = kybGetByIdSlice.actions;

export default kybGetByIdSlice.reducer;
