import { Box, Skeleton, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import { useState } from 'react';
import React from 'react';

import {
  CustomDrawerFooter,
  PrimaryButton,
  RadioCard,
} from '../../../../components/custom';
import { useAppSelector } from '../../../../store/hooks';
import { TStep } from './DirectorsAdditionalInfoStep';

type TAdditionalInformationType = 'documents' | '';

interface AdditionalInformationTypeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<TStep>>;
  setDirectorId: React.Dispatch<React.SetStateAction<string>>;
}

export default function AdditionalInformationType({
  setCurrentStep,
  setDirectorId,
}: AdditionalInformationTypeProps) {
  const { status: directorStatus, value: directorValue } = useAppSelector(
    (state) => state.getDirectorAdditionalInfo
  );

  const [selectedInformationType, setSelectedInformationType] =
    useState<TAdditionalInformationType>('');

  const handleTypeChange = (value: TAdditionalInformationType) => {
    setSelectedInformationType(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'informationTypes',
    defaultValue: selectedInformationType,
    onChange: () => handleTypeChange('documents'),
  });

  const group = getRootProps();

  const handleSubmit = () => {
    setCurrentStep(selectedInformationType as TStep);
  };

  return (
    <>
      <Box mt={4} mb={6}>
        <Text color="#000" mb={1}>
          Director Additional Information Required
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Select the director to provide the information requested
        </Text>
      </Box>

      <Box display="flex" flexDirection="column">
        <Stack spacing={6} flex={1} mb={2} {...group}>
          {directorStatus === 'loading' ? (
            <>
              <Skeleton
                height="104px"
                startColor="ash.300"
                endColor="ash.400"
              />
              <Skeleton
                height="104px"
                startColor="ash.300"
                endColor="ash.400"
              />
              <Skeleton
                height="104px"
                startColor="ash.300"
                endColor="ash.400"
              />
            </>
          ) : (
            directorValue?.data?.map((director) => {
              const radio = getRadioProps({ value: director?.id });

              return (
                <Box
                  key={director?.id}
                  onClick={() => setDirectorId(director?.id)}
                >
                  <RadioCard {...radio}>
                    <Text fontWeight={500} color="#000" mb={2}>
                      {director?.firstName + ' ' + director?.lastName}
                    </Text>
                    <Text color="#99a0aC">
                      {director?.reason ??
                        'Kindly provide documents requested for this director'}
                    </Text>
                  </RadioCard>
                </Box>
              );
            })
          )}
        </Stack>

        <CustomDrawerFooter>
          <PrimaryButton
            onClick={handleSubmit}
            isDisabled={!selectedInformationType}
            w="full"
          >
            Continue
          </PrimaryButton>
        </CustomDrawerFooter>
      </Box>
    </>
  );
}
