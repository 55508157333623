import {
  AbsoluteCenter,
  Box,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {
  AnimatedLogo,
  CustomDrawerFooter,
  CustomFileInput,
  CustomFormErrorMessage,
  PrimaryButton,
  SecondaryButton,
  StatusBox,
} from '../../../../components/custom';
import { fileValidation } from '../../../../constants/validationConstants';
import {
  convertFileToBase64,
  formatCamelCaseToWords,
} from '../../../../helpers/utilityFunctions';
import useGetUIErrorMessage from '../../../../hooks/useGetUIErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getDirectorAdditionalInfoAsync } from '../../../../store/slices/addDirector/getDirectorAdditionalInfoSlice';
import {
  resetSubmitDirectorAdditionalInfo,
  submitDirectorAdditionalInfoAsync,
} from '../../../../store/slices/addDirector/submitDirectorAdditionalInfoSlice';
import { TStep } from './DirectorsAdditionalInfoStep';

interface AdditionalDocumentsFormProps {
  directorId: string;
  documentNames: string[];
  setCurrentStep: React.Dispatch<React.SetStateAction<TStep>>;
  directorCount: number;
  close: () => void;
}

export default function AdditionalDocumentsForm({
  directorId,
  documentNames,
  setCurrentStep,
  directorCount,
  close,
}: AdditionalDocumentsFormProps) {
  const dispatch = useAppDispatch();

  const businessIdFromStorage = localStorage.getItem('businessId') ?? '';

  const { status, value } = useAppSelector(
    (state) => state.submitDirectorAdditionalInfo
  );

  const errorMessage = useGetUIErrorMessage({
    message: value?.message,
    errors: value?.errors,
    customMessage:
      'An error occurred while submitting addition documents, try again.',
  });

  const initialValues: {
    [key: string]: File | null;
  } = documentNames.reduce((a, b) => ({ ...a, [b]: null }), {});

  const validationSchema = Yup.object().shape(
    documentNames.reduce(
      (accumulator, documentName) => ({
        ...accumulator,
        [documentName]: fileValidation.required(`${documentName} is required`),
      }),
      {}
    )
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const documentsInBase64 = await Promise.all(
        Object.keys(values).map(async (key, index) => ({
          name: documentNames[index],
          file: values[key] ? await convertFileToBase64(values[key]) : '',
        }))
      );

      const proofOfAddressUrl =
        documentsInBase64?.filter((document) =>
          document?.name.includes('proofOfAddress')
        )?.[0]?.file ?? '';

      const identityVerificationUrl =
        documentsInBase64?.filter((document) =>
          document?.name.includes('IdentificationDocument')
        )?.[0]?.file ?? '';

      dispatch(
        submitDirectorAdditionalInfoAsync({
          directorId,
          businessId: businessIdFromStorage,
          proofOfAddressUrl,
          identityVerificationUrl,
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = formik;

  const handleFileUploadChange = (fieldName: string, file: File) => {
    setFieldValue(fieldName, file);
  };

  if (status === 'loading') {
    return (
      <Box
        position="relative"
        zIndex="2"
        height="80vh"
        top="-120px"
        background="#fff"
      >
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (status === 'success') {
    return (
      <>
        <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
          <StatusBox
            variant="success"
            title="Documents Submitted Successfully!"
          >
            {value?.message ?? ''}
          </StatusBox>
        </AbsoluteCenter>
        <CustomDrawerFooter direction="column">
          <>
            <PrimaryButton
              onClick={() => {
                close();
                dispatch(
                  getDirectorAdditionalInfoAsync({
                    approvalStatus: 'information_requested',
                  })
                );
              }}
              w="full"
              sx={{ marginBottom: '12px' }}
            >
              Done
            </PrimaryButton>
            {directorCount > 1 && (
              <SecondaryButton
                onClick={() => {
                  setCurrentStep('informationType');
                  dispatch(resetSubmitDirectorAdditionalInfo());

                  dispatch(
                    getDirectorAdditionalInfoAsync({
                      approvalStatus: 'information_requested',
                    })
                  );
                }}
                w="full"
              >
                Continue to Other Directors
              </SecondaryButton>
            )}
          </>
        </CustomDrawerFooter>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to upload documents">
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        {/* <CustomDrawerFooter> */}
        <PrimaryButton
          onClick={() => {
            dispatch(resetSubmitDirectorAdditionalInfo());
          }}
          w="full"
        >
          Retry
        </PrimaryButton>
        {/* </CustomDrawerFooter> */}
      </>
    );
  }

  return (
    <>
      <Box mt={4} mb={6}>
        <Text color="#000" mb={1}>
          Director Additional information required
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Please upload the required documents to proceed.
        </Text>
      </Box>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Stack spacing={8} flex="1">
              {documentNames.map((documentName, index) => (
                <FormControl
                  key={documentName + index}
                  id={documentName + index}
                  isInvalid={Boolean(
                    touched[documentName] && errors[documentName]
                  )}
                >
                  <FormLabel>
                    {formatCamelCaseToWords(documentName ?? '')}
                  </FormLabel>
                  <CustomFileInput
                    onChange={(value) =>
                      handleFileUploadChange(documentName, value)
                    }
                    fieldName={documentName}
                    isInvalid={Boolean(
                      touched[documentName] && errors[documentName]
                    )}
                    value={values[documentName]}
                    setFieldTouched={setFieldTouched}
                    accept=".png, .jpg, .jpeg, .pdf"
                  />
                  <CustomFormErrorMessage>
                    {touched[documentName] && errors[documentName]}
                  </CustomFormErrorMessage>
                </FormControl>
              ))}
            </Stack>

            <CustomDrawerFooter>
              <PrimaryButton type="submit" isDisabled={!isValid} w="full">
                Save and Continue
              </PrimaryButton>
            </CustomDrawerFooter>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
