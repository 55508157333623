import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface ISubmitResidentPermitRequest {
  nationality: string;
  residentPermit?: string;
}

interface ISubmitResidentPermitResponse {
  success: boolean;
  message: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<ISubmitResidentPermitResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const submitResidentPermitAsync = createAsyncThunk(
  'residentPermit/submit',
  async (
    values: ISubmitResidentPermitRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const firstName = state.auth.value?.data?.user?.firstName;
      const lastName = state.auth.value?.data?.user?.lastName;
      const email = state.auth.value?.data?.user?.email;
      const phoneNumber = state.auth.value?.data?.user?.phoneNumber;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post(
        '/representative-nationality-verification',
        { ...values, firstName, lastName, email, phoneNumber },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ISubmitResidentPermitResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const submitResidentPermitSlice = createSlice({
  name: 'residentPermit/submit',
  initialState,
  reducers: {
    resetSubmitResidentPermit: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitResidentPermitAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(submitResidentPermitAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ISubmitResidentPermitResponse;
      })
      .addCase(submitResidentPermitAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISubmitResidentPermitResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetSubmitResidentPermit } = submitResidentPermitSlice.actions;

export default submitResidentPermitSlice.reducer;
