import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ICddResponse {
  questionId: string;
  answers: string;
}

export interface ICreateBusinessDirectorRequest {
  firstName?: string;
  lastName?: string;
  businessId?: string;
  email?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  gender?: string;
  bvn?: string;
  nin?: string;
  cddResponses?: ICddResponse[];
  residentialAddress?: string;
  state?: string;
  lga?: string;
  landmark?: string;
  identityVerificationUrl?: string;
  proofOfAddressUrl?: string;
}

interface ICreateBusinessDirectorResponse {
  status: boolean;
  message: string;
  data?: {
    id?: string;
    businessId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    gender?: string;
    createdAt?: string;
    updatedAt?: string;
    bvn?: string;
    nin?: string;
    identityVerificationUrl?: string;
    cddResponses?: [
      {
        answers: string;
        questionId: string;
      }
    ];
    residentialAddress?: string;
    state?: string;
    lga?: string;
    landmark?: string;
    proofOfAddressUrl?: string;
    approvalStatus?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateBusinessDirectorResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createBusinessDirectorAsync = createAsyncThunk(
  'businessDirector/create',
  async (
    values: ICreateBusinessDirectorRequest,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post('/directors', values, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateBusinessDirectorResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(showAlert({ status: 'error', description: errorMessage }));

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessDirectorCreationSlice = createSlice({
  name: 'businessDirector/create',
  initialState,
  reducers: {
    resetBusinessDirectorCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBusinessDirectorAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createBusinessDirectorAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateBusinessDirectorResponse;
      })
      .addCase(createBusinessDirectorAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateBusinessDirectorResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessDirectorCreation } =
  businessDirectorCreationSlice.actions;

export default businessDirectorCreationSlice.reducer;
