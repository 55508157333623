import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IAllDirectorsList } from '../../../types/addDirector';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IDirectorObject {
  allDirectors?: IAllDirectorsList[];
  outstandingDirector?: number;
  totalNumberOfDirectors?: number;
}

export interface IGetDirectorsByBusinessIdResponse {
  status?: boolean;
  message?: string;
  data?: IDirectorObject;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetDirectorsByBusinessIdResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getDirectorsByBusinessIdAsync = createAsyncThunk(
  'businessDirectors/id',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/business-directors?businessId=${businessId}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const directorsGetSlice = createSlice({
  name: 'directors/getById',
  initialState,
  reducers: {
    resetGetDirectorsByBusinessId: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDirectorsByBusinessIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getDirectorsByBusinessIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetDirectorsByBusinessIdResponse;
      })
      .addCase(getDirectorsByBusinessIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetDirectorsByBusinessIdResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetDirectorsByBusinessId } = directorsGetSlice.actions;

export default directorsGetSlice.reducer;
